"use client";

import Link from "next/link";
import Image from "next/image";
import { images } from "@/themes";
import { Button, Result } from "antd";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error("ERROR", error);
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="mb-8">
        <Link href="/">
          <Image
            src={images.gingerlogo}
            alt="logo"
            width={150}
            height={100}
            objectFit="cover"
          />
        </Link>
      </div>
      <Result
        status="500"
        title="We ran into a problem..."
        subTitle="We're sorry, but it looks like the web app stopped working. This is likely due to a temporary technical issue."
        extra={[
          <Button
            type="primary"
            onClick={reset}
            key="reload"
            className="bg-ginger-green"
          >
            Click to reload
          </Button>,
        ]}
      />
    </div>
  );
}
